<template>
  <event-management
    class="event-management__extended"
    :get-roles="getRoles"
    :get-categories="getCategories"
    :get-event="getEvent"
    :save-event="saveEvent"
    :update-event="updateEvent"
    :get-applications="getApplications"
  />
</template>

<script>
import { EventManagement } from 'operations';
import _service from '@/api/operations.api.js';
import { getApplications } from '@/api/code.api.js';

export default {
  name: 'EventManagementWrapper',
  components: { EventManagement },
  methods: {
    getRoles() {
      return _service.fetchRol();
    },
    getCategories() {
      return _service.fetchCategory();
    },
    getEvent(id) {
      return _service.fetchEventById(id);
    },
    saveEvent(params) {
      return _service.saveEvent(params);
    },
    updateEvent(params) {
      return _service.updateEvent(params);
    },
    getApplications() {
      return getApplications();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables/colors.scss';
.event-management__extended ::v-deep {
  span {
    .custom-link.color--green {
      color: map-get($colors, 'green') !important;
    }
    .custom-link.color--red {
      color: map-get($colors, 'red') !important;
    }
  }
}
</style>
