import { http } from '@/core/api/';

const calendarEventBaseURL = 'api/calendarEvent';

export default {
  fetchCategory() {
    return http.get(`${calendarEventBaseURL}/categories`);
  },
  fetchRol() {
    return http.get(`api/message/roles`);
  },
  fetchUsers(rolId) {
    return http.get(`api/message/users/${rolId}`);
  },
  fetchEventById(id) {
    return http.get(`${calendarEventBaseURL}/${id}`);
  },
  deleteEvent(id) {
    return http.delete(`${calendarEventBaseURL}/delete/${id}`);
  },
  updateEvent(data) {
    return http.put(`${calendarEventBaseURL}/edit`, data).catch((e) => {
      throw e.response.data.message;
    });
  },
  saveEvent(data) {
    return http.post(`${calendarEventBaseURL}/save`, data).catch((e) => {
      throw e.response.data.message;
    });
  },
  fetchEvents(categoryId, applicationId) {
    return http.get(
      `${calendarEventBaseURL}/events/0?categoryId=${categoryId}&applicationId=${applicationId}`
    );
  },
};
