import { http } from '@/core/api';

export const getPeiCode = (codeType, longName) =>
  http.get(`api/code/getpeicode`, {
    params: {
      codeType,
      longName,
    },
  });
export const getAssistanceApprovalStatus = () =>
  http.get(`api/code/assistanceapprovalstatus`);

export const getAppointmentSystemUrl = () =>
  http.get(`api/code/appointmentsystemurl`);

export const getPeiSection = () => http.get(`api/code/peisection`);

export const getPeiSubSection = (sectionId) =>
  http.get(`api/code/peisubsection/${sectionId}`);

export const getApplications = () => http.get('api/code/applications');

export const getPublicPortalUrl = () =>
  http.get(`api/code/publicportalurl`);
